import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FC, FormEvent } from "react";
import * as Yup from "yup";

import Button from "../../../atoms/button/Button";
import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import DogsAndCatsSVG from "../../../images/pages/results/pet-family/dogsAndCats.svg";
import SectionTitle from "../../../molecules/section-title/SectionTitle";
import { DealEventProperties } from "../../../organisms/deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import PageStepForm from "../../../organisms/page-step-form/PageStepForm";
import {
  useGetPetHasFamily,
  useSetPetHasFamily,
} from "../../../organisms/pet-family/use-cases/pet-family-use-case";
import {
  usePrepareForBind,
  useResult,
  useUpdateResult,
} from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { fakeChipNumber } from "../../../settings/pet";
import { Events, track } from "../../../utils/analytics";
import {
  handlePrepareForBindErrors,
  handleUpdateResultErrors,
} from "../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import {
  getMultiplePetsDiscount,
  shouldAllowFakeChipNumber,
} from "../../../utils/locale-configuration-utils";
import * as styles from "../../PetFamily.module.scss";

interface PetFamilyFormValues {
  multiplePets: "yes" | "no";
}

interface Image {
  default: string;
}

const dogAndCatSVG: Image =
  require("../../../images/pages/results/pet-family/dogAndCat.svg") as Image;

const dogAndCatSVGSrc: string = dogAndCatSVG.default;

const Page: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult();
  const updateResult = useUpdateResult();
  const petHasFamily = useGetPetHasFamily();
  const setPetHasFamily = useSetPetHasFamily();
  const prepareForBind = usePrepareForBind();
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();
  const hasFakeChipNumber: boolean | undefined =
    result?.country && shouldAllowFakeChipNumber(result.country);

  useTrackPageViewed(Events.PET_FAMILY_VIEWED_BROWSER);

  const trackAnswer = (answer: string): void => {
    const properties = {
      ...dealEventProperties,
      petHasFamily: answer,
    };

    track(Events.PET_HAS_FAMILY, properties);
  };

  const handleChange = (e: FormEvent<HTMLFormElement>): void => {
    const inputElement = e.target as HTMLInputElement;
    const answer: string = inputElement.value;

    trackAnswer(answer);
    setPetHasFamily(answer === "yes");
  };

  async function handleFakeChipUpdate() {
    if (!updateResult) {
      return;
    }

    try {
      await updateResult({ pet_chip: fakeChipNumber });
    } catch (err) {
      if (err instanceof Error) {
        handleUpdateResultErrors(err, "pet_family");
        alert(t("landing.error_message"));
      }
    }
  }

  const petFamilyFormData = {
    initialValues: {
      multiplePets: petHasFamily ? "yes" : petHasFamily !== null ? "no" : null,
    },
    validationSchema: Yup.object({
      multiplePets: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: async (
      values: PetFamilyFormValues,
      { setSubmitting }: FormikHelpers<PetFamilyFormValues>
    ) => {
      setSubmitting(true);

      try {
        if (hasFakeChipNumber) {
          await handleFakeChipUpdate();
        }

        await prepareForBind();
        void navigate(`/results/${result?.uuid}/details/`);
      } catch (err) {
        if (err instanceof Error) {
          handlePrepareForBindErrors(err, "pet_family");
          alert(t("landing.error_message"));
        }
      } finally {
        setSubmitting(false);
      }
    },
    children: (props: FormikProps<PetFamilyFormValues>) => {
      const { isSubmitting } = props;
      const multiplePetsDiscountVariant: string = result
        ? `multiplePetsDiscount${getMultiplePetsDiscount(result.country)}`
        : "";

      return (
        <Form
          className={cn(styles.petFamilyForm)}
          onChange={(e: FormEvent<HTMLFormElement>) => handleChange(e)}
        >
          <Field type="radio" name="multiplePets" value="yes" id="yes" />
          <label htmlFor="yes">{t("pg_pet_family.answer.yes")}</label>

          <Field type="radio" name="multiplePets" value="no" id="no" />
          <label htmlFor="no">{t("pg_pet_family.answer.no", { petName: result?.pet_name })}</label>
          {petHasFamily && (
            <section className={cn(styles.petHasFamilyCard)}>
              <DogsAndCatsSVG
                className={cn(styles.dogsAndCatsSvg, {
                  [styles[multiplePetsDiscountVariant]]: multiplePetsDiscountVariant,
                })}
              />
              <SectionTitle
                title={t("pg_pet_family.pet_has_family.title")}
                subtitle={t("pg_pet_family.pet_has_family.subtitle")}
                headingLevel={4}
                adoptionClassName={cn(styles.petHasFamilyCardTitle)}
              />
            </section>
          )}

          <Button
            type="submit"
            disabled={isSubmitting || petHasFamily === null}
            isLoading={isSubmitting}
          >
            {t("pg_pet_family.cta")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_pet_family.title")}
            subtitle={t("pg_pet_family.subtitle")}
            image={dogAndCatSVGSrc}
            formData={petFamilyFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-pet-family", PageId.petFamily);

export const query = graphql`
  query FamilyQuestionQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
