import { atom, selector } from "recoil";

export const petHasFamily = atom<boolean | null>({
  key: "petHasFamily",
  default: null,
});

export const petHasFamilyState = selector<boolean | null>({
  key: "petFamilyState",
  get: ({ get }) => get(petHasFamily),
  set: ({ set }, newValue) => {
    set(petHasFamily, newValue);
  },
});
