import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { petHasFamilyState } from "../state/atoms";

export const useGetPetHasFamily = (): boolean | null => useRecoilValue(petHasFamilyState);

export function useSetPetHasFamily(): (petHasFamily: boolean) => void {
  const setPetHasFamily = useSetRecoilState(petHasFamilyState);

  return useCallback((petHasFamily: boolean) => setPetHasFamily(petHasFamily), [setPetHasFamily]);
}
